import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .hero-image-area {
    position: relative;

    h1 {
      color: ${colors?.darkblue};
      line-height: 60px;

      @media (max-width: 1000px) {
        font-size: 25px;
        line-height: 40px;
      }

      span {
        color: ${colors?.orange};
      }
    }
    .get-btn {
      background: ${colors?.darkblue};
      cursor: pointer;
    }
    .video-icon,
    .list-below {
      color: ${colors?.darkblue};
    }
    .grp-img {
      position: absolute;
      bottom: 10px;
      right: 50px;
      height: 70px;
      width: 220px;
      background: #fff;
   
      @media (max-width: 1000px) {
        width: 180px;
        height: 60px;

        
        p {
          font-size: 10px;
        }
      }
      @media (max-width: 768px) {
        width: 140px;
        p {
          font-size: 8px;
        }
      }
      
      }
      
      }
      

      .badge {
        position: absolute;
        top: -10px;
        left: -10px;
        background: ${colors?.darkblue};
        // height:10px;
        // width:10px;
        border-radius: 50%;
      }
      .small-pic {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        img {
          object-fit: contain;
          @media (max-width: 768px) {
            width: 35px;
            height: 35px;
          }
        }
        @media (max-width: 768px) {
          height: 40px;
          width: 40px;
        }
      }
    }
    .grp-img3 {
      position: absolute;
      bottom: 10px;
      right: 50px;
      height: 70px;
      width: 220px;
      background: #fff;
      @media screen and (min-width: 768px) and (max-width: 1000px) { 
        width: 180px;
        height: 60px;
        top:-50px;

        
        p {
          font-size: 10px;
        }
      }
      @media (max-width: 768px) {
        width: 140px;
        p {
          font-size: 8px;
        }
      }
      
      }
      
      }
      

      .badge {
        position: absolute;
        top: -10px;
        left: -10px;
        background: ${colors?.darkblue};
        // height:10px;
        // width:10px;
        border-radius: 50%;
      }
      .small-pic {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        img {
          object-fit: contain;
          @media (max-width: 768px) {
            width: 35px;
            height: 35px;
          }
        }
        @media (max-width: 768px) {
          height: 40px;
          width: 40px;
        }
      }
    }
    .grp-img2 {
      position: absolute;
      right: 100px;
      top: -40px;
      height: 70px;
      width: 200px;
      background: #fff;

      @media (max-width: 1200px) {
        right: 35px;
      }
      @media (max-width: 1000px) {
        width: 180px;
        height: 60px;
        top: 200px;
        right: 15px;

        p {
          font-size: 10px;
        }
      }
      @media (max-width: 768px) {
        width: 160px;
        p {
          font-size: 8px;
        }
      }
      p {
        color: ${colors?.orange};
      }
      .small-pic {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: #feebd6;
        @media (max-width: 768px) {
          height: 45px;
          width: 45px;
        }
      }
    }
    .img1 {
      @media (max-width: 1000px) {
        height: 160px;
      }
      @media (max-width: 768px) {
        height: 140px;
      }
    }
    .img2 {
      @media (max-width: 1000px) {
        height: 140px;
      }
    }
    .img3 {
      object-fit: contain;

      @media (max-width: 1000px) {
        height: 250px;
        width: 150px;
        margin-left: 10px;
      }
      @media (max-width: 1200px) {
        width: 180px;
        margin-left: 10px;

      }
      @media (max-width: 768px) {
        width: 150px;
        margin-left: 10px;

      }
    }

    .right-blk {
      @media (max-width: 768px) {
        margin-top: 20px;
        justify-content: end !important;
      }
    }
    .pointsec {
      @media (max-width: 768px) {
        flex-direction: row !important;
      }
    }
    .heroTag3 {
      width: 160px;
      line-height: 1;
      @media (max-width: 975px) {
        
          width: auto;
          
      }
    }
   
    
  }
`;
