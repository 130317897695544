import { createGlobalStyle } from "styled-components";
import { colors, fonts } from "../element/elements.js";

export const GlobalStyle = createGlobalStyle`
    html {
        // color      : ${colors.bg1};
        font-size  : 13px;
        line-height: 1.4;
        font-family: ${fonts.roboto}    ;

    }

    ::-moz-selection {
        background : #b3d4fc;
        text-shadow: none;
    }

    ::selection {
        background : #b3d4fc;
        text-shadow: none;
    }

    hr {
        display   : block;
        height    : 1px;
        border    : 0;
        border-top: 1px solid ${colors.border3};
        margin    : 1em 0;
        padding   : 0;
    }

    audio,
    canvas,
    iframe,
    img,
    svg,
    video {
        vertical-align: middle;
    }

    fieldset {
        border : 0;
        margin : 0;
        padding: 0;
    }

    textarea {
        resize: vertical;
    }

    body {
        font-size     : 13px;
        // background    : #ffffff;
        // color         : ${colors.text1};
        letter-spacing: 0.3px;
        font-family   : ${fonts.roboto}!important;
    }

    h1,
    h1 a {
        font-size: 40px ;
    }

    h2,
    h2 a {
        font-size: 32px;
    }

    h3,
    h3 a {
        font-size: 28px;
    }

    h4,
    h4 a {
        font-size: 24px;
    }

    h5,
    h5 a {
        font-size: 20px;
    }

    h6,
    h6 a {
        font-size: 16px;
    }

    h1,
    h1 a,
    h2,
    h2 a,
    h3,
    h3 a,
    h4,
    h4 a,
    h5,
    h5 a,
    h6,
    h6 a {
        font-family: ${fonts.poppins};
        margin     : 0;
    }

    a,
    button,
    li,
    p {
        font-size     : 13px;
        font-family   : ${fonts.roboto} !important;
        margin        : 0;
        letter-spacing: 0.3px;
    }

    ul {
        padding: 0;
        margin : 0;
    }

    a,
    a:active,
    a:focus,
    a:hover,
    button:focus,
    button:hover {
        text-decoration: none!important;
        outline        : none;
    }

    a,
    button {
        transition: all 0.2s ease;
    }

    input:focus,
    textarea:focus {
        outline: none;
    }

    .padding-fix {
        padding-left : 0;
        padding-right: 0;
    }

    .padding-fix-r {
        padding-right: 0;
    }

    .padding-fix-l {
        padding-left: 0;
    }

    .margin-fix {
        margin-left : 0;
        margin-right: 0;
    }
    .light-mode {
        color-scheme: light;
        background-color: white !important;
        color: black;

        body{
            background-color: white !important;
            color: black;
        }
      }
      .dark-mode {
        color-scheme: dark;
        background-color: black !important;
        color: white;

        
        body, ul.dropdown,.grp-img,.grp-img2,.grp-img3,.test-card,.test-card-inner,.Gbutton,.Fbutton,.bg-white{
            background-color: #2d2d2d !important;
            color: white !important;
        }
        .plan-block{
            background-color: #2d2d2d !important;
            color: white !important;
            &:hover {
                background: ${colors?.darkbluehover} !important;
                scale: 1.1;
                color: #fff;
        
        
                
              }
        }
        .active-plan-block{
            background: ${colors?.darkbluehover}!important;
             scale:1.1;
            color: #fff;
          }
        input,.statc-card{
            background-color: #333 !important;
            color:#fff !important;
            border: 1px solid rgb(68 68 68)!important;
            ::placeholder {
                color: #fff;
              }
        }
        .form-check-input:checked{
            background-color: ${colors?.orange} !important;
        }
        textarea{
            background-color: rgb(70 68 68) !important;
            color:#fff !important;
            ::placeholder {
                color: #fff;
              }
        }
        .shadow-lg{
            box-shadow: rgb(108 108 108 / 83%) 0px 1px 6px !important;
        }
        .shadow{
            box-shadow: rgb(84 84 84 / 68%) 1px 3px 10px !important;
        }
        .shadow-sm{
            box-shadow: rgb(84 84 84 / 68%) 1px 3px 5px !important;
            background: #2d2d2d;
        }
        .list-card,.list-item{
            background: #2d2d2d;
        }
       .modal-content{
            background-color: #333 !important;
            border-radius:5px;
        }
        .jyddap{ // For the data table footer pagination section
            background-color: rgb(32 31 31) !important;
            color:#fff;
        }
        .jTciGK{// For the page no. drop-down
            background-color: #333 !important;
        }
        .ewQGKi{// For rows in data table
            background-color: #333 !important;
            color:#fff;
        }
        .hDRNNe{ //For data table header
            background-color: rgb(32 31 31) !important;
            color:#fff;
        }
        .css-12iiol5-control,.css-1g3p1vi-control{
            background-color: #333 !important;
            color:#fff !important;
            input{
                background:none !important;
                border:none !important;
            }
        }
        .top-bar2{
            border-bottom: 1px solid rgb(43 43 43) !important;
        }
        .login-box, .registration-box{
            border: 1px solid rgb(43 43 43) !important;

        }
        .jLrPtQ .registration-page hr::after{
            background:#000000;
        }
        .sticky-menu{
            background-color: rgb(32 31 31) !important;
            box-shadow: rgb(84 84 84 / 68%) 1px 3px 10px !important;
        }
        .iMztaf .registration-page hr::after{
            background:#000000;
        }
     
        .card-main { // Card in search teacher page
            box-shadow: rgb(84 84 84 / 68%) 1px 1px 3px !important;
      
            &:hover {
              box-shadow: rgb(84 84 84 / 68%) 2px 2px 10px !important;
            }
        }
        
        .loader-main{
            background:#212121 !important; 
        }
        .service-box,.become-card  {
            background:#212121!important;
        }

        .item-content{
            p.title {
                a {
                    color: #fff !important;

                    &:hover {
                        color : ${colors.orange} !important;
                    }
                }
            }
        }
    
        .chat-client{
            color:#000000;
           textarea{
            background: rgb(255 255 255) !important;
            color:#000000 !important;
            
        }
        }
       
        
      }
    //   dark mode end

      ::-webkit-scrollbar {
        width: 5px;
        border-radius:10px;
      }
      ::-webkit-scrollbar-track {
        background-color: #dadada; 
        border-radius:5px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${colors?.darkblue}; 
        border-radius: 6px; 
      }
      
     
      ::-webkit-scrollbar-thumb:hover {
        background-color: ${colors?.darkbluehover};
    }
    .controlskit[_ngcontent-ng-c2939039469]{
        background: #fdfdfd00 !important;
    }
  
`;
