import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerTwo.js";
import { colors } from "./common/element/elements.js";
import { useTranslation } from "react-i18next";

function FooterTwo() {
  const { t } = useTranslation();
  return (
    <Styles>
      {/* Footer Two */}
      <footer className="footer2 pt-5 ">
        <Container>
          <Row>
            <Col md={4} className="text-white mb-4">
             <Link to='/'> <img
                src={process.env.PUBLIC_URL + "/assets/images/Logoheader.webp"}
                loading="lazy"
                alt="logo"
                height={"29px"}
                width={"150px"}
                style={{ objectFit: "contain" }}
              /></Link>
              <p className="mt-3  w-75 text-black fw-normal  ">
               {t('footerPara')}
              </p>
            </Col>
            <Col md={2} className="text-white mb-4">
              <h5 className="fw-bold ">{t("about")}</h5>
              <ul className="mt-3 ">
                <li>
                  <Link to={process.env.PUBLIC_URL + "/whoAreWe"}> {t("whoAreWe")}</Link>
                </li>

                <li>
                  <Link to={process.env.PUBLIC_URL + "/legalNotice"}>
                    {t("legalNotice")}
                  </Link>
                </li>
                <li >
                  <Link to={process.env.PUBLIC_URL + "/onlineCourses"}>{t("onlineCourse")}</Link>
                </li>
                <li >
                  <Link to={process.env.PUBLIC_URL + "/waitingRoom"}>{t("waitingRoom").charAt(0)+t("waitingRoom").slice(1).toLowerCase()}</Link>
                </li>
                <li >
                  <Link to={process.env.PUBLIC_URL + "/MyLevel"}>{t("myLevel")}</Link>
                </li>
              </ul>
            </Col>
            <Col md={3} className="text-white mb-4">
              <h5 className="fw-bold ">{t("assistance")}</h5>
              <ul className="mt-3 ">
                <li>
                  <Link to="/helpcenter">{t("helpCenter")}</Link>
                </li>
                <li>
                  <Link to="/contact">{t("contact")}</Link>
                </li>
              </ul>
            </Col>
            <Col md={3} className="text-white mb-4 position-relative ">
              <h5 className="fw-bold ">{t("followUs")} :</h5>
              <div className="d-flex gap-3 mt-3 flex-wrap   ">
                <a href="https://www.facebook.com/Acadeclic/" target="_blank" aria-label='facebook'>
                  <div className="social-blk p-2">
                    <Icon
                      icon="mingcute:facebook-fill"
                      width={25}
                      color={colors?.orange}
                    />
                  </div>
                </a>
                <a href="https://twitter.com/acadeclic" target="_blank" aria-label='twitter'>
                  <div className="social-blk p-2">
                    <Icon
                      icon="line-md:twitter-x"
                      width={25}
                      color={colors?.orange}
                    />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/acadeclic_france/"
                  target="_blank"
                  aria-label='instagram'
                >
                  <div className="social-blk p-2">
                    <Icon
                      icon="lets-icons:insta-fill"
                      width={25}
                      color={colors?.orange}
                    />
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/in/acadeclic-france-356b452b9/"
                  target="_blank"
                  aria-label='linkedin'
                >
                  <div className="social-blk p-2">
                    <Icon
                      icon="mingcute:linkedin-fill"
                      width={25}
                      color={colors?.orange}
                    />
                  </div>
                </a>
                <a
                  href="https://www.youtube.com/channel/UC7vY_nctNH7O4TFFFI_26og"
                  target="_blank"
                  aria-label='youtube'
                >
                  <div className="social-blk p-2">
                    <Icon
                      icon="mingcute:youtube-fill"
                      width={25}
                      color={colors?.orange}
                    />
                  </div>
                </a>
              </div>
              
            </Col>
          </Row>
        </Container>
        <Col md="12" className="mt-5 ">
          <div className="copytext-area text-center mt-2 text-warning py-2 ">
            <p className="fw-normal  ">
              {t("cpyRight")} &copy; 2024 | {t("paraFooter")}{" "}
              <Icon icon="ph:heart-duotone" color="red" width={25} /> 
             
            </p>
          </div>
        </Col>

        {/* Back To Top  */}
        <BackToTop />
      </footer>
    </Styles>
  );
}

export default FooterTwo;
