import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import enLocales from "./langs/en.json";
import frLocales from "./langs/fr.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { ...enLocales },
    fr: { ...frLocales },
  },
  lng: "en",
});
