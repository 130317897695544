import React, { useState, useEffect } from "react";

const Timer = ({ onTimerComplete }) => {
  const [seconds, setSeconds] = useState(120);
  
  useEffect(() => {
      const intervalId = setInterval(() => {
          setSeconds((prevSeconds) => {
              // Stop the countdown if it reaches zero
              if (prevSeconds === 0) {
                  clearInterval(intervalId);
                  onTimerComplete(false);
                  return 0;
                }
        return prevSeconds - 1;
      });
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [onTimerComplete]);

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  return (
   
      <p className="container fw-bold ">{formatTime()}<span className="text-primary  "> in you can Re-send.</span></p>
  
  );
};

export const AcceptTimer=({onTimerComplete})=>{
  const [seconds, setSeconds] = useState(180);
  
  useEffect(() => {
      const intervalId = setInterval(() => {
          setSeconds((prevSeconds) => {
              // Stop the countdown if it reaches zero
              if (prevSeconds === 0) {
                  clearInterval(intervalId);
                  onTimerComplete(false);
                  return 0;
                }
        return prevSeconds - 1;
      });
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [onTimerComplete]);

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return ` ${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")} `;
  };

  return (
   
      <p className=" fw-bold text-danger "> {formatTime()} </p>
  
  );
}

export default Timer;
