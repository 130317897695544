import React, { useEffect, useState, Suspense, lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../components/common/Loader";
import Dashboard from "../pages/dashboard/Dashboard";

const HomeTwo = lazy(() => import("../HomeTwo"));
// const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const MyClasse = lazy(() => import("../pages/myClasses/MyClasses"));
const Favourites = lazy(() => import("../pages/favourites/Favourites"));
const MyAccount = lazy(() => import("../pages/myAccount/MyAccount"));
const FirstLogin = lazy(() =>
  import("../pages/Teacher/FirstTimeLogin.js/FirstLogin")
);
const MyPayments = lazy(() => import("../pages/Teacher/MyPayments/MyPayments"));
const MyAdvert = lazy(() => import("../pages/Teacher/myAdvert/MyAdvert"));
const StudPublicProfile = lazy(() =>
  import("../pages/studPublicProfile/StudPublicProfile")
);
const UpdateMyAdvert = lazy(() =>
  import("../pages/Teacher/myAdvert/UpdateMyAdvert")
);
const Subscription = lazy(() =>
  import("../pages/Teacher/Subscription/Subscription")
);
const PageNotFound = lazy(() => import("../pages/404/PageNotFound"));
const TeacherDetails = lazy(() =>
  import("../pages/searchTeacher/TeacherDetails")
);
const ZoomMeeting = lazy(() => import("../components/ZoomMeeting"));
const ViewStatistics = lazy(() => import("../components/ViewStatistics"));

function TeacherRoutes() {
  const [access, setAccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const path = [
    "/dashboard",
    "/myclasses",
    "/myAccount",
    "/myPublicProfile",
    "/firstlogin",
    "/MyPayments",
    "/myadvert",
    "/updatemyadvert",
    "/Subscription",
    "/Teacher-Details",
    "/ZoomClasses",
    "/ViewStatistics",
  ];
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    setAccess(path.includes(location.pathname));
    access !== null && setLoading(false);
  }, [location.pathname, access]);

  return (
    <Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <Switch>
        {access && !loading ? (
          <>
            <Route path={`/dashboard`} component={Dashboard} />
            <Route path={`/myclasses`} component={MyClasse} />
            <Route path={`/myAccount`} component={MyAccount} />
            <Route path={`/firstlogin`} component={FirstLogin} />
            <Route path={`/MyPayments`} component={MyPayments} />
            <Route path={`/myadvert`} component={MyAdvert} />
            <Route path={`/myPublicProfile`} component={StudPublicProfile} />
            <Route path={`/updatemyadvert`} component={UpdateMyAdvert} />
            <Route path={`/Subscription`} component={Subscription} />
            <Route path="/Teacher-Details" component={TeacherDetails} />
            <Route path="/ZoomClasses" component={ZoomMeeting} />
            {authUserData?.membership !== "Basic" && (
              <Route path="/ViewStatistics" component={ViewStatistics} />
            )}
          </>
        ):(
          !loading &&  <Route component={PageNotFound} />
        )}
      </Switch>
    </Suspense>
  );
}

export default TeacherRoutes;
