import axios from "../axios";
import auth from "./auth";

const Student = {
  updateGeneralInformation(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put("students/update-student", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  updatePassword(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("users/change-password", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  sendOTP() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("users/send-otp")
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  verifyOTP(otp) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("users/verify-otp", otp)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  deleteAccount(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .delete("students/delete-student", id)
        .then(({ data }) => {resolve(data);
        auth.destroyToken('distory')})
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getFavourite() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("channel")
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  addToFavCollection(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("channel/add-to-channel", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  deleteFavouriteCollection(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .delete("channel", id)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  upadateNotificationSetting(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("set-student-notification", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
          err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getClassHistory(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`get-classes-history/${id}`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          err?.response?.status === 401 && auth?.destroyToken();
          reject(err);
        });
    });
  },
  takeClasses(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("take-class", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  createFavourite(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("channel", data)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getLevels() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("get-user-level")
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getStudentById(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`/students/${id}`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getCommissionData() {
    return new Promise((resolve, reject) => {
        axios.setToken();
      axios
        .get(`/get-commission`)
        .then(({ data }) => resolve(data))
        .catch((error) => {reject(error);
          error?.response?.status===401&&auth?.destroyToken();
        });
    });
  },
  getAdvertBySubject(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`adverts/get-adverts-by-subject/${id}`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          err?.response?.status === 401 && auth?.destroyToken();
          reject(err);
        });
    });
  },
  exitWaitingRoom(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`/exit-waiting-room/${id}`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          err?.response?.status === 401 && auth?.destroyToken();
          reject(err);
        });
    });
  },
};

export default Student;
