import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .explorCourses {
    margin-top: 50px;
    .sec-title {
      h4 {
        color: ${colors?.darkblue};
      }
      .right-block {
        background: #eef1f6;
        height: fit-content;
        border-radius: 20px;
        @media(max-width:1000px){
          font-size:10px;
        }
        @media(max-width:770px){
          margin-top:20px;
        }
        div {
          cursor: pointer;
          font-weight: 500;
          padding-left: 10px;
          padding-right: 5px;
        }
        .active {
          background: #fff;
          padding-left: 10px;
          padding-right: 5px;
          border-radius: 20px;
        }
      }
    }

    .card-blk {
      width:550px;
      background: none;
      .img-blk{
        cursor:pointer;
      }
      @media (max-width: 769px) {
        width: 330px;
        object-fit: none;
        background: aliceblue;
      }

      img{
        object-fit:contain;
        @media (max-width: 769px) {
          width: 320px;
         
        }
      }

      .heading,
      .name {
        color: ${colors?.darkblue};
        font-size: 14px;
        font-weight: 500;
      }
      p {
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .icon-blk {
        .level-blk {
          text-transform: uppercase;

          .dot-blk {
            height: 10px;
            width: 10px;
          }
        }
      }
    }
    .rs-carousel{
      width: 70%;
      background:none ;
      margin:0px auto;
    }
    .sr-only{
      color:${colors?.orange};
      font-weight:500;
    }
    .rs-carousel{
      background:none !important;
      height:fit-content;
    }
  }
`;
